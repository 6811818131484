<template>
  <el-container style="margin:0;padding: 0">
    <el-header style="height: 80px">
        <div style="background-color: white;height: 100%;border-bottom: 1px solid #ccc">
          <el-row>
            <el-col :span="5">
                <img @click="goHome" width="150px" height="80px" style="margin-left: 180px;cursor: pointer;" src="../../assets/img/jyouxi.png"/>
            </el-col>
            <el-col :span="12">
              <ul v-show="true" id="gameTypeUl">
                <li :class="{ menuActive: 0 === activeIndex }"><a @click="goHome()">首页</a></li>
<!--                <li :class="{ menuActive: 1 === activeIndex }"><a @click="goSearch('角色扮演',1)">测试测试</a></li>-->
<!--                <li :class="{ menuActive: 2 === activeIndex }"><a @click="goSearch('竞速体育',2)">测试测试</a></li>-->
<!--                <li :class="{ menuActive: 3 === activeIndex }"><a @click="goSearch('生存恐怖',3)">测试测试</a></li>-->
<!--                <li :class="{ menuActive: 4 === activeIndex }"><a @click="goSearch('模拟经营',4)">测试测试</a></li>-->
<!--                <li :class="{ menuActive: 5 === activeIndex }"><a @click="goSearch('格斗游戏',5)">测试测试</a></li>-->
<!--                <li :class="{ menuActive: 6 === activeIndex }"><a @click="goSearch('射击游戏',6)">测试测试</a></li>-->
<!--                <li :class="{ menuActive: 7 === activeIndex }"><a @click="goSearch('即时战略',7)">测试测试</a></li>-->
<!--                <li :class="{ menuActive: 8 === activeIndex }"><a @click="goSearch('动作冒险',8)">测试测试</a></li>-->
<!--                <li :class="{ menuActive: 9 === activeIndex }"><a @click="goSearch('休闲益智',9)">测试测试</a></li>-->
                <li :class="{ menuActive: 1 === activeIndex }"><a @click="goSearch('角色扮演',1)">角色扮演</a></li>
                <li :class="{ menuActive: 2 === activeIndex }"><a @click="goSearch('竞速体育',2)">竞速体育</a></li>
                <li :class="{ menuActive: 3 === activeIndex }"><a @click="goSearch('生存恐怖',3)">生存恐怖</a></li>
                <li :class="{ menuActive: 4 === activeIndex }"><a @click="goSearch('模拟经营',4)">模拟经营</a></li>
                <li :class="{ menuActive: 5 === activeIndex }"><a @click="goSearch('格斗游戏',5)">格斗游戏</a></li>
                <li :class="{ menuActive: 6 === activeIndex }"><a @click="goSearch('射击游戏',6)">射击游戏</a></li>
                <li :class="{ menuActive: 7 === activeIndex }"><a @click="goSearch('即时战略',7)">即时战略</a></li>
                <li :class="{ menuActive: 8 === activeIndex }"><a @click="goSearch('动作冒险',8)">动作冒险</a></li>
                <li :class="{ menuActive: 9 === activeIndex }"><a @click="goSearch('休闲益智',9)">休闲益智</a></li>
                <li :class="{ menuActive: 10 === activeIndex }"><a @click="goToolsPage(10)">游戏工具</a></li>
              </ul>
            </el-col>
            <el-col :span="4" style="margin-top: 20px;">
              <div style="width: 280px;">
                <span style="color:#e6a23c;cursor: pointer">
                  <el-input @keyup.enter.native="searchEnterFun" placeholder="搜一搜 总有您想要玩的~" v-model="gameName">
                    <i @click="searchFun" slot="prefix" class="el-input__icon el-icon-search"></i>
                  </el-input>
                </span>
              </div>
            </el-col>
            <el-col :span="2" style="margin-top: 30px;">
              <div id="loginBox" v-if="loginname==''" style="width: 180px;">
                <span @click="toLogin" style="color:#e6a23c;cursor: pointer">
                  <i class="el-icon-user-solid" style="font-size: 20px;"></i>登录
                </span>
              </div>
              <div v-if="loginname!=''" style="width: 100%;">
                <span>
                  <el-popover
                    placement="bottom"
                    width="400"
                    trigger="hover">
                    <div style="display: -webkit-inline-flex" class="userBox">
                      <el-upload
                        class="avatar-uploader"
                        :headers="uploadHeaders"
                        action="/renren-admin/sys/oss/upload"
                        :show-file-list="false"
                        name="file"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload">
                         <el-tooltip content="点击修改头像" placement="bottom" effect="light">
                          <img :src="headUrl" style="border-radius: 50%;width:50px;height:50px;border:1px solid #ccc;cursor: pointer" />
                         </el-tooltip>
                      </el-upload>

                        <span v-if="limitTime!==''">
                          <el-tooltip :content="'您是尊贵的VIP会员，畅玩本站所有游戏！有效期至：'+ limitTime" placement="bottom" effect="light">
                             <img src="../../assets/img/vip/vip.png" width="90" height="30" style="cursor: pointer;margin-left: 10px"/>
                          </el-tooltip>
                         </span>
                         <span v-if="limitTime===''">
                          <el-tooltip content="您还不是VIP，点击开通尊贵VIP，享全站游戏下载特权！" placement="bottom" effect="light">
                             <img @click="dialogKeyFormVisible = true" src="../../assets/img/vip/novip.png" width="90" height="30" style="cursor: pointer;margin-left: 10px"/>
                          </el-tooltip>
                         </span>
                      <img @click="dialogSpKeyFormVisible = true" title="特殊礼物" src="../../assets/img/vip/gift.png" width="30" height="30" style="cursor: pointer;margin-left: 10px"/>
                    </div>
                    <img @click="outLogin" src="../../assets/img/loginOut.png" width="30" height="30" title="退出" style="float: right;cursor: pointer;margin-top: 10px"/>
                    <div style="border-top: 1px solid #ccc"></div>
                    <el-row>
                      <el-col :span="15">
                          <div class="userCardInfos" style="background-color: #459aff;color: white;height: 100px;cursor: pointer;
                              border-radius: 5%;width: 250px;display: inline-block;margin-top: 10px;font-weight: bold">
                              <div v-if="limitTime!==''&&limitTime.includes('2100')" style="width: 100%;height: 100%;line-height: 30px;margin-left: 20px;margin-top: 15px">
                                 <p>会员信息：永久会员</p>
                                 <p>到期时间：无限期下载</p>
                              </div>
                             <div v-if="limitTime!==''&&!limitTime.includes('2100')" style="width: 100%;height: 100%;line-height: 30px;margin-left: 20px;margin-top: 15px">
                               <p>会员信息：一日会员<a  @click="dialogKeyFormVisible = true" style="color: yellow;text-decoration: underline">(升级永久会员)</a></p>
                                 <p>到期时间：{{limitTime}}</p>
                              </div>
                             <div v-if="limitTime==''" style="width: 100%;height: 100%;line-height: 30px;margin-left: 20px;margin-top: 15px">
                                 <p>会员信息：普通会员</p>
                                 <p>到期时间：暂未加入会员</p>
                              </div>
                          </div>
                      </el-col>
                      <el-col :span="9">
                           <div  class="userCardInfos" style="background-color:#10c469;color: white;height: 100px;cursor: pointer;
                              text-align: center;border-radius: 5%;width: 100%;display: inline-block;margin-left: 5px;margin-top: 10px">
                               <div v-if="limitTime!==''" style="width: 100%;height: 100%;line-height: 30px;margin-left: 5px;margin-top: 18px">
                                  <p>您的会员特权</p>
                                  <p>免费下载全站游戏</p>
                               </div>
                               <div @click="dialogKeyFormVisible = true" v-if="limitTime==''" style="width: 100%;height: 100%;line-height: 30px;margin-left: 5px;margin-top: 18px">
                                    <span style="line-height: 60px;">激活一日或永久vip</span>
                                </div>
                          </div>
                      </el-col>
                    </el-row>
                    <div slot="reference" style="cursor: pointer;width: 100px;float: right">
                      <div class="userBox" style="margin-left: 86px">
                        <img :src="headUrl" style="border-radius: 50%;width:30px;height:30px;border:1px solid #ccc" />
                        <span style="width: 20px">{{loginname}}</span>
                        <el-tooltip content="问题反馈" placement="bottom" effect="light">
                              <img  v-if="loginname!=''" @click="noFoundGame" src="../../assets/img/question_commit.png" width="30" height="30"
                                    style="cursor: pointer;margin-top: 5px;margin-left: 80px"/>
                        </el-tooltip>
                      </div>
                    </div>
                  </el-popover>
                </span>
              </div>
            </el-col>
          </el-row>
        </div>

      <el-dialog title="" :visible.sync="dialogLoginFormVisible" width="400px">
      <el-form>
        <el-form-item label="" label-width="90px">
          <img src="../../assets/img/jyouxi.png" width="180" height="100"/>
        </el-form-item>
        <el-form-item label="用户名" label-width="90px">
          <el-input v-model="username" style="width: 220px" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="密码" label-width="90px">
          <el-input v-model="password" type="password" style="width: 220px" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="" label-width="10px" style="text-align: center">
          <el-button id="loginBtn" type="primary" style="width: 250px;margin-top: 20px;background: #34495e;" @click="doLogin">登录</el-button>
        </el-form-item>
        <el-form-item label="" label-width="10px" style="text-align: center">
          <el-button id="loginBtn" type="success" style="width: 250px;margin-top: 5px;" @click="registerUser">注册并登录</el-button>
        </el-form-item>
        <el-form-item label="" label-width="10px" style="text-align: center">
          <span style="color:red;font-weight: bolder">温馨提示：还未注册的，可以直接填好用户名，密码（记住噢），点击【注册并登录】</span>
        </el-form-item>
      </el-form>
    </el-dialog>

      <el-dialog title="游戏问题反馈" :visible.sync="dialogFormVisible" width="500px">
        <el-form>
          <el-form-item label="游戏名称" label-width="120px">
            <el-input v-model="needGame" style="width: 300px" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="备注（选填：问题描述）" label-width="120px">
            <el-input type="textarea" v-model="content" style="width: 300px" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveNeedGame">确 定</el-button>
        </div>
      </el-dialog>

      <el-dialog title="VIP开通 - 密钥" :visible.sync="dialogKeyFormVisible" width="500px">
        <el-form>
          <el-form-item label="密钥：" label-width="80px">
            <el-input v-model="gameKey" style="width: 350px" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogKeyFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="openVip()">确 定</el-button>
        </div>
      </el-dialog>

      <el-dialog title="特殊礼物" :visible.sync="dialogSpKeyFormVisible" width="300px">
        <el-form>
          <el-form-item label="特殊码：" label-width="80px">
            <el-input v-model="spCode" style="width: 150px" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogSpKeyFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="openSpPage()">确 定</el-button>
        </div>
      </el-dialog>
    </el-header>
  </el-container>
</template>
<script>
import { EventBus } from '../../event/event-bus.js';
export default {
  components: {
  },
  data() {
    return {
      spCode:"",
      dialogSpKeyFormVisible:false,
      gameKey:"",
      needGame:"",
      dialogFormVisible:false,
      dialogLoginFormVisible:false,
      dialogKeyFormVisible:false,
      loginname:"",
      username:"",
      password:"",
      content:"",
      gameName:"",
      limitTime:"",
      gameType:"",
      isActive:true,
      activeIndex: -1,
      headUrl:"",
      uploadHeaders:{
        "token": sessionStorage.getItem("token")
      }
    };
  },
  // 卸载
  beforeDestroy() {
  },
  created() {
    this.getVipInfos();
    if (sessionStorage.getItem("userName")) {
      this.loginname = sessionStorage.getItem("userName");
    } else {
      this.loginname = "";
    }
    this.getUserInfo();

  },
  mounted() {
  },
  methods: {
    openSpPage(){
        this.$axios.get(this.$gameApiurls.checkSpCode+"?spCode="+this.spCode)
            .then((res) => {
                if(res.data){
                    let paramsData = {gameName:this.gameName, gameType: this.gameType}
                    EventBus.$emit('spGameEvent', paramsData);
                    this.$router.push({path:'/gameSpSearch', query:{spCode:this.spCode}})
                    this.dialogSpKeyFormVisible = false;
                }else{
                    this.$message({
                        message: '请输入正确的特殊吗噢！！',
                        type: 'error'
                    });
                }
            });
    },
    handleAvatarSuccess(res, file) {
      this.headUrl = URL.createObjectURL(file.raw);
      let newHeaderUrl = res.data.src;
      this.updateUserHeader(newHeaderUrl)
    },
    updateUserHeader(newHeaderUrl){
      let userId = sessionStorage.getItem("userId");
      this.$axios.post(this.$gameApiurls.updateHeaderUrl,{headerUrl:newHeaderUrl, id: userId})
        .then((res) => {
          if(res.data){
            this.$message({
              message: '修改成功',
              type: 'success'
            });
          }else{
            this.$message({
              message: '修改失败',
              type: 'error'
            });
          }
        });
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    getUserInfo(){
      this.$axios.get(this.$gameApiurls.getLoginUserInfo)
        .then((res) => {
          if(res.code==0){
            if(res.data!=null){
              this.headUrl = res.data.headUrl==null?"https://www.jgame.icu/file/jyouxi.png":res.data.headUrl;
            }
          }
        });
    },
    openVip(){
      let userId = sessionStorage.getItem("userId");
      this.$axios.post(this.$gameApiurls.openVIP,{gameKey:this.gameKey, userId: userId})
        .then((res) => {
          if(res.code==401){
            this.$message({
              message: '登录已过期，请重新登录哦！ ',
              type: 'error'
            });
            setTimeout(()=>{
              window.location.reload();
            },1000)
            return;
          }
          if(res.data){
            this.$message({
              message: '恭喜！加入VIP成功，快去下载喜欢的游戏吧!! (≥▽≤) ',
              type: 'success'
            });
            setTimeout(()=>{
              window.location.reload();
            },1000)
          }else{
            this.$message({
              message: '密钥错误,请输入正确密钥，谢谢！',
              type: 'error'
            });
          }
        });
    },
    outLogin(){
      this.$confirm("确定退出登录吗?", "退出", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          sessionStorage.clear();
          window.location.reload();
        })
        .catch(() => {
        });
    },
    toLogin(){
      this.dialogLoginFormVisible = true
    },
    getVipInfos(){
      let userId = sessionStorage.getItem("userId")
      if(userId!=null&&userId!="undefined"){
        setTimeout(()=>{
          this.$axios.get(this.$gameApiurls.getUserVipInfo+"?userId="+userId)
            .then((res) => {
              if(res.code==0){
                if(res.data!=null){
                  this.limitTime = res.data.limitTime;
                }else{
                  this.limitTime = "";
                }
              }
            });
        },1000)
      }
    },
    doLogin(){
      let loginFrom = {username:this.username,password:this.password}
      this.$axios.post(this.$gameApiurls.frontLogin,loginFrom)
        .then((res) => {
          if(res.code==0){
            this.$message({
              message: '登录成功，请尽情畅玩吧！≥▽≤',
              type: 'success'
            });
            setTimeout(()=>{
              let limitTime = res.data.limitTime==undefined?"":res.data.limitTime
              sessionStorage.setItem('userName', this.username);
              sessionStorage.setItem('token', res.data.token);
              sessionStorage.setItem('userId', res.data.userId);
              sessionStorage.setItem('limitTime', limitTime);
              this.loginname = this.username;
              this.limitTime = limitTime;
              window.location.reload();
              this.dialogLoginFormVisible = false
            },500)
          }else{
            this.$message({
              message: '登录失败,用户名或密码不对！(＞﹏＜)',
              type: 'error'
            });
          }
        });
    },
    registerUser(){
      if(this.username==""||this.password==""){
        this.$message({
          message: '请输入用户名密码，进行注册',
          type: 'error'
        });
        return;
      }
      this.$axios.post(this.$gameApiurls.regiterUser,{username:this.username,password:this.password})
        .then((res) => {
          console.log(res)
          if(res.code==0){
            this.doLogin()
          }else{
            this.$message({
              message: '注册失败,当前用户已存在，请直接登录！≥▽≤',
              type: 'error'
            });
          }
        });
    },
    goHome(){
      this.activeIndex = 0;
      this.gameName = "";
      this.gameType = "";
      this.$router.push({path:'/'})
    },goSearch(gameType, activeIndex){
      this.gameType = gameType;
      this.activeIndex = activeIndex;
      let paramsData = {gameType:this.gameType,gameName:this.gameName}
      EventBus.$emit('someEvent', paramsData);
      this.$router.push({path:'/gameSearch', query:{gameName:this.gameName, gameType: gameType}})
    },goToolsPage(activeIndex){
          this.activeIndex = activeIndex;
          this.$router.push({path:'/gameToolsPage'});
      },noFoundGame(){
      this.needGame = "";
      this.dialogFormVisible = true
    },saveNeedGame(){
      this.$message({
        message: '已为您反馈【'+this.needGame+'】,请耐心等待！马上补上哦！',
        type: 'success'
      });
      this.$axios.post(this.$gameApiurls.saveNeedGame,{name:this.needGame,content:this.content})
        .then((res) => {
          let data = res.data.list;
          this.datas = data
        });
      this.dialogFormVisible = false
    },searchEnterFun(){
      let paramsData = {gameName:this.gameName, gameType: this.gameType}
      EventBus.$emit('someEvent', paramsData);
      this.$router.push({path:'/gameSearch', query:{gameName:this.gameName, gameType: this.gameType}})
    },searchFun(){
      let paramsData = {gameName:this.gameName, gameType: this.gameType}
      EventBus.$emit('someEvent', paramsData);
      this.$router.push({path:'/gameSearch', query:{gameName:this.gameName, gameType: this.gameType}})
    }

  },
};
</script>

<style>
body {
  height: 100vh;
  background-color: #f4fafe;
}

#headerUl>li{
  float: left;
  list-style: none;
}

.el-input__inner {
  border-radius: 20px!important;
}
#gameTypeUl>li{
  float: left;
  list-style: none;
  margin-left: 20px;
  font-size: 16px;
  font-weight: 700;
}
#gameTypeUl{
  height: 50px;
  margin-top: 32px;
}

#gameTypeUl a:hover {
  opacity: .8;
  font-size: 16px;
  cursor: pointer;
}

.menuActive{
  border-bottom: 2px solid blue;height: 30px
}

.userBox {
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中，如果也需要水平居中的话 */
}

/* 如果你想要图片和文字之间有间隔 */
.userBox img + span {
  margin-left: 10px; /* 或者你需要的间距 */
}

.userCardInfos:hover{
  opacity: 0.8;
}

</style>
